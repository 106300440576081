import React, { useCallback, useEffect, useState } from 'react';
import { initialize, LDFlagSet, LDContext, LDOptions } from 'launchdarkly-js-client-sdk';

import Context from './Context';
import defaultFeatureFlags from '../../../featureFlags.dist';

const LOCALE_SEPARATOR = '-';

const FeatureFlagProvider = ({ locale, children }: { locale: string; children: React.ReactNode }) => {
  const [featureFlags, setFeatureFlags] = useState<LDFlagSet | null>(null);

  const determineLanguageCode = useCallback(() => {
    return locale.split(LOCALE_SEPARATOR)[0];
  }, [locale]);

  useEffect(() => {
    if (!__CONFIG__.launchDarkly.clientSideId) {
      try {
        const localFeatureFlags: LDFlagSet = require('../../../featureFlags').default; // eslint-disable-line @typescript-eslint/no-var-requires, import/no-unresolved
        setFeatureFlags({ ...defaultFeatureFlags, ...localFeatureFlags });
      } catch (error) {
        setFeatureFlags(defaultFeatureFlags);
      }
      return;
    }

    const ldClient = initialize(
      __CONFIG__.launchDarkly.clientSideId,
      {
        kind: 'user',
        anonymous: true,
        language: determineLanguageCode(),
      } as LDContext,
      { sendEvents: false } as LDOptions,
    );
    ldClient.on('ready', () => {
      const featureFlags = ldClient.allFlags();
      setFeatureFlags(featureFlags);
    });
  }, []);

  if (!featureFlags) {
    return null;
  }

  return <Context.Provider value={featureFlags}>{children}</Context.Provider>;
};

export default FeatureFlagProvider;
