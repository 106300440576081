import { useEffect } from 'react';
import UrlGenerator from '../../Infrastructure/Routing/UrlGenerator';

const FinishIdentityProviderLogin = () => {
  const appUrl = UrlGenerator().appRoot();

  useEffect(() => {
    if (window.opener) {
      (window.opener as Window).postMessage('TL_FOCUS_SESSION_RENEWED', appUrl);
      window.close();
    }
  });

  return null;
};

export default FinishIdentityProviderLogin;
