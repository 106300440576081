import UrlGenerator from '../../Infrastructure/Routing/UrlGenerator';

export const getSignupUrl = (query?: URLSearchParams) => {
  const appUrl = UrlGenerator().appRoot();
  const signupUrl = UrlGenerator().signupRoot();

  const intended = query?.get('intended');
  const flow = query?.get('flow');

  if (!intended || flow !== 'oauth') {
    return signupUrl;
  }

  const redirectUrl = encodeURIComponent(`${appUrl}${intended}`);
  return `${signupUrl}?redirect_url=${redirectUrl}`;
};

export const isMobileApp = (query?: URLSearchParams) => {
  const intended = query?.get('intended');

  if (!__CONFIG__.mobileAppBundleId || !intended) {
    return false;
  }

  const decodedIntendedQuery = decodeURIComponent(intended);
  const intendedQueryStart = decodedIntendedQuery.indexOf('?');
  const intendedQuerySlice = decodedIntendedQuery.slice(intendedQueryStart + 1);
  const intendedQuery = new URLSearchParams(intendedQuerySlice);

  const redirectUri = intendedQuery?.get('redirect_uri');
  return redirectUri?.includes(__CONFIG__.mobileAppBundleId) ?? false;
};
