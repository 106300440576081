import { decamelizeKeys } from 'humps';

const createFetchOptions = (data: Record<string, any> = {}, headers: Record<string, any> = {}): RequestInit => {
  return {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(decamelizeKeys(data)),
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };
};

export default createFetchOptions;
