import React from 'react';
import { Box, TextSmall } from '@teamleader/ahoy';
import { translate } from '@teamleader/i18n';
import generalStyles from '../styles.css';
import LoginWithIdentityProviderButton from './LoginWithIdentityProviderButton';
import { IdentityProvider } from './types';
import styles from './styles.css';

const LoginWithIdentityProvider = () => {
  const identityProviders = Object.values(IdentityProvider);

  return (
    <Box marginTop={4} marginBottom={3}>
      <Box className={styles['identity-provider-buttons']} display="flex" flexDirection="row" marginBottom={3}>
        {identityProviders.map((identityProvider) => (
          <LoginWithIdentityProviderButton key={identityProvider} identityProvider={identityProvider} />
        ))}
      </Box>
      <Box display="flex">
        <TextSmall className={generalStyles['or-login-with-divider']}>{translate('orLoginWith')}</TextSmall>
      </Box>
    </Box>
  );
};

export default LoginWithIdentityProvider;
