const track = (event: string, source: string, meta = {}) => {
  if (!__CONFIG__.segment.enabled) {
    console.group('Tracking user action');
    console.info(`event: ${event}`);
    console.info(`source: ${source}`);
    console.info('meta:', meta);
    console.groupEnd();
    return;
  }
  window.analytics.track(event, { source, ...meta });
};

export default track;
