import React, { useState } from 'react';
import { translate } from '@teamleader/i18n';
import { Button, TextSmall, Heading1, Label } from '@teamleader/ahoy';
import { Form, Field } from 'react-final-form';
import { Navigate } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';

import API from '../../Infrastructure/API';
import useQuery from '../../Infrastructure/Routing/useQuery';
import { getFormFieldErrorFromMeta } from '../../Infrastructure/Forms/utils';
import PasswordInput from '../../Components/PasswordInput';
import { validatePassword } from '../../Components/PasswordInput/validation';
import { isUndefined } from 'lodash';

interface FormValues {
  newPassword?: string;
}

const ResetPassword = () => {
  const [isSubmitSuccessFul, setIsSubmitSuccessFul] = useState(false);
  const query = useQuery();
  const token = query.get('token');

  const handleSubmit = async (values: FormValues) => {
    try {
      await API.password.reset({
        newPassword: values.newPassword!,
        token: String(token),
      });
    } catch (error) {
      return { [FORM_ERROR]: translate('resetPasswordError.generic') };
    }

    setIsSubmitSuccessFul(true);
  };

  const validate = (values: FormValues) => {
    const passwordError = validatePassword(values.newPassword);
    if (!isUndefined(passwordError)) {
      return { newPassword: translate(passwordError) };
    }

    return {};
  };

  if (isSubmitSuccessFul || !token) {
    return <Navigate to="/" replace />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit, submitError, submitting }) => (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={handleSubmit}>
          <Heading1 marginBottom={3}>{translate('resetPassword')}</Heading1>

          <Field name="newPassword">
            {({ input, meta }) => {
              return (
                <Label marginBottom={3}>
                  {translate('newPassword')}
                  <PasswordInput
                    placeholder={translate('newPassword')}
                    autoFocus
                    {...input}
                    error={getFormFieldErrorFromMeta(meta)}
                    size="large"
                    shouldShowPasswordRequirements
                  />
                </Label>
              );
            }}
          </Field>

          {submitError && (
            <TextSmall marginVertical={3} color="ruby" tint="dark">
              {submitError}
            </TextSmall>
          )}

          <Button
            size="large"
            level="primary"
            fullWidth
            marginTop={3}
            marginBottom={5}
            type="submit"
            processing={submitting}
          >
            {translate('saveNewPassword')}
          </Button>
        </form>
      )}
    />
  );
};

export default ResetPassword;
