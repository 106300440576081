import { isEmpty, isUndefined } from 'lodash';

const REGEX__MINIMUM_LENGTH = /.{8,}/;
const REGEX_AT_LEAST_ONE_UPPER_CASE = /[A-Z]+/;
const REGEX_AT_LEAST_ONE_LOWER_CASE = /[a-z]+/;
const REGEX_AT_LEAST_ONE_NUMBER = /[0-9]+/;
const REGEX_AT_LEAST_ONE_SPECIAL_CHARACTER = /[^A-Za-z0-9\s]+/;

export const validatePassword = (input?: string) => {
  if (isUndefined(input) || isEmpty(input)) {
    return 'validation.password.required';
  }

  if (!REGEX__MINIMUM_LENGTH.test(input)) {
    return 'validation.password.length';
  }

  if (!REGEX_AT_LEAST_ONE_UPPER_CASE.test(input)) {
    return 'validation.password.upperCase';
  }

  if (!REGEX_AT_LEAST_ONE_LOWER_CASE.test(input)) {
    return 'validation.password.lowerCase';
  }

  if (!REGEX_AT_LEAST_ONE_NUMBER.test(input)) {
    return 'validation.password.number';
  }

  if (!REGEX_AT_LEAST_ONE_SPECIAL_CHARACTER.test(input)) {
    return 'validation.password.specialCharacter';
  }

  return undefined;
};
