class FetchError extends Error {
  private status: number;
  private statusText: string;
  private body: unknown;

  constructor(status: number, statusText: string, body: unknown) {
    super(statusText);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }

  public getStatus() {
    return this.status;
  }

  public getStatusText() {
    return this.statusText;
  }

  public getBody<T = unknown>() {
    return this.body as T;
  }

  public getTitle() {
    const body = this.body as any;
    return body.title ? String(body.title) : undefined;
  }
}

export default FetchError;
