import { useContext } from 'react';

import Context from './Context';

const useFeatureFlags = () => {
  const featureFlags = useContext(Context);
  return featureFlags;
};

export default useFeatureFlags;
