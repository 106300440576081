import React, { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Heading1, Button, Input, Link, TextSmall, Label } from '@teamleader/ahoy';
import { translate } from '@teamleader/i18n';
import { Field, FormRenderProps } from 'react-final-form';

import { getFormFieldErrorFromMeta } from '../../Infrastructure/Forms/utils';
import PasswordInput from '../../Components/PasswordInput';
import track from '../../Infrastructure/Analytics/track';
import useQuery from '../../Infrastructure/Routing/useQuery';

import LoginWithIdentityProvider from './IdentityProvider/LoginWithIdentityProvider';
import { getSignupUrl, isMobileApp } from './utils';
import useFeatureFlag from '../../Infrastructure/FeatureFlags/useFeatureFlag';
import FeatureFlags from '../../Infrastructure/FeatureFlags/FeatureFlags';

const Credentials = ({
  formRenderProps,
  isRedirecting,
  hasTrialLinkHidden,
  identityProviderError,
}: {
  formRenderProps: FormRenderProps;
  isRedirecting: boolean;
  hasTrialLinkHidden?: boolean;
  identityProviderError: string | null | ReactElement;
}) => {
  const { submitting, submitError } = formRenderProps;

  const query = useQuery();
  const prefilledEmail = query.get('email');

  const signupUrl = getSignupUrl(query);

  const hasIdentityProviderDisabledForMobile = useFeatureFlag(FeatureFlags.DisableIdentityProviderForMobile);
  const isMobile = isMobileApp(query);
  const canSignupWithIdentityProvider = !(hasIdentityProviderDisabledForMobile && isMobile);

  return (
    <div>
      <Heading1 marginBottom={3}>{translate('login')}</Heading1>

      {canSignupWithIdentityProvider && <LoginWithIdentityProvider />}

      <Field name="email" initialValue={prefilledEmail ? String(prefilledEmail) : undefined}>
        {({ input, meta }) => {
          return (
            <Label marginBottom={3}>
              {translate('email')}
              <Input
                type="email"
                placeholder={translate('email')}
                autoFocus={!prefilledEmail}
                {...input}
                error={getFormFieldErrorFromMeta(meta)}
                size="large"
                disabled={!!prefilledEmail}
              />
            </Label>
          );
        }}
      </Field>

      <Field name="password">
        {({ input, meta }) => (
          <Label marginBottom={3}>
            {translate('password')}
            <PasswordInput
              placeholder={translate('password')}
              {...input}
              error={getFormFieldErrorFromMeta(meta)}
              size="large"
              autoFocus={!!prefilledEmail}
            />
          </Label>
        )}
      </Field>

      {submitError && (
        <TextSmall marginTop={3} color="ruby" tint="dark">
          {submitError}
        </TextSmall>
      )}

      {identityProviderError && !submitError && (
        <TextSmall marginTop={3} color="ruby" tint="dark">
          {identityProviderError}
        </TextSmall>
      )}

      <Button
        size="large"
        level="primary"
        fullWidth
        marginTop={3}
        marginBottom={3}
        type="submit"
        processing={submitting || isRedirecting}
      >
        {translate('logIn')}
      </Button>

      <TextSmall marginBottom={2}>
        <Link
          to={`/forgot-password?${query.toString()}`}
          element={RouterLink}
          inherit={false}
          onClick={() => {
            track('loginPage.forgotPasswordClicked', 'loginPage');
          }}
        >
          {translate('forgotPasswordQuestion')}
        </Link>
      </TextSmall>

      {!hasTrialLinkHidden && (
        <TextSmall>
          {translate('noAccountYetQuestion')}{' '}
          <Link
            href={signupUrl}
            inherit={false}
            onClick={() => {
              track('loginPage.freeTrialClicked', 'loginPage');
            }}
          >
            {translate('getAFreeTrial')}
          </Link>
        </TextSmall>
      )}
    </div>
  );
};

export default Credentials;
