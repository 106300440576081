enum FeatureFlags {
  MarketingBanner = 'core.enable-alternative-login-sidebar-image',
  DisableIdentityProviderForMobile = 'signup.disable-identity-provider-for-mobile',
}

export type FeatureFlagList = {
  [key in keyof typeof FeatureFlags]?: string | boolean;
};

export default FeatureFlags;
