import React, { useState } from 'react';
import {
  Input,
  Icon,
  IconVisibleOffSmallOutline,
  IconVisibleSmallOutline,
  Flex,
  TextSmall,
  InputProps,
} from '@teamleader/ahoy';

import styles from './styles.css';
import { translate } from '@teamleader/i18n';

interface PasswordInputProps extends InputProps {
  shouldShowPasswordRequirements?: boolean;
}

const PasswordInput = ({ shouldShowPasswordRequirements = false, ...inputProps }: PasswordInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handlePasswordVisiblityToggle = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <Flex direction="column" gap={1}>
      <Input
        type={isPasswordVisible ? 'text' : 'password'}
        suffix={
          <Icon
            color="neutral"
            tint="darkest"
            onClick={handlePasswordVisiblityToggle}
            marginRight={2}
            className={styles['password-visibility-toggle']}
          >
            {isPasswordVisible ? <IconVisibleOffSmallOutline /> : <IconVisibleSmallOutline />}
          </Icon>
        }
        {...inputProps}
      />
      {shouldShowPasswordRequirements && (
        <TextSmall color="neutral" tint="darkest">
          {translate('validation.password')}
        </TextSmall>
      )}
    </Flex>
  );
};

export default PasswordInput;
