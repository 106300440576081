import { Button } from '@teamleader/ahoy';
import React from 'react';
import { IdentityProvider } from './types';

import { idpIconMap, idpNameTranslationMap } from './constants';
import { getIdentityProviderCapitalCaseName, getIdentityProviderRedirectUrl } from './utils';
import useQuery from '../../../Infrastructure/Routing/useQuery';
import track from '../../../Infrastructure/Analytics/track';
import { translate } from '@teamleader/i18n';

interface LoginWithIdentityProviderButtonProps {
  identityProvider: IdentityProvider;
  isFullText?: boolean;
}

const LoginWithIdentityProviderButton = ({
  identityProvider,
  isFullText = false,
}: LoginWithIdentityProviderButtonProps) => {
  const queryParams = useQuery();

  const handleLoginWithIdentityProvider = () => {
    track('loginPage.loginWithClicked', 'loginPage', {
      identityProvider,
    });
  };

  const Icon = idpIconMap[identityProvider];

  return (
    <Button
      title={translate(idpNameTranslationMap[identityProvider])}
      size="large"
      level="secondary"
      fullWidth
      icon={<Icon />}
      element="a"
      href={getIdentityProviderRedirectUrl(identityProvider, queryParams)}
      onClick={handleLoginWithIdentityProvider}
    >
      {isFullText ? getIdentityProviderCapitalCaseName(identityProvider) : null}
    </Button>
  );
};

export default LoginWithIdentityProviderButton;
