import React from 'react';
import { Box } from '@teamleader/ahoy';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import I18nProvider from './Infrastructure/I18n/Provider';
import FeatureFlagsProvider from './Infrastructure/FeatureFlags/Provider';
import MarketingBanners from './MarketingBanners';
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import FinishIdentityProviderLogin from './Pages/FinishIdentityProviderLogin';
import Logo from './Assets/Images/logo.svg';
import theme from './styles.css';
import RedirectIfLoggedIn from './Infrastructure/Routing/RedirectIfLoggedIn';

const App = () => {
  return (
    <I18nProvider>
      <FeatureFlagsProvider locale={__CONFIG__.localeOverwrite || navigator.language}>
        <RedirectIfLoggedIn />
        <Router>
          <Box display="flex" className={theme['app']}>
            <MarketingBanners />
            <Box className={theme['page-content']}>
              <Box className={theme['logo']}>
                <Logo />
              </Box>
              <Box className={theme['inner']}>
                <Box className={theme['inner-content']}>
                  <Routes>
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/finish-identity-provider-login" element={<FinishIdentityProviderLogin />} />
                    <Route path="/" element={<Login />} />
                  </Routes>
                </Box>
              </Box>
            </Box>
          </Box>
        </Router>
      </FeatureFlagsProvider>
    </I18nProvider>
  );
};

export default App;
