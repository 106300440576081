enum Errors {
  InvalidCredentials = 'Invalid credentials',
  MfaIsRequired = 'Multi factor authentication token is required',
  MfaIsInvalid = 'Multi factor authentication token is invalid',
  AccountIsSuspended = 'Account is suspended',
  MultipleUsersFound = 'Multiple users for email address found',
  TooManyAttempts = 'Too many attempts',
}

export default Errors;
