import { IdentityProvider } from './types';

import appleIcon from './icons/apple.svg';
import googleIcon from './icons/google.svg';
import microsoftIcon from './icons/microsoft.svg';

export const idpIconMap = {
  [IdentityProvider.Google]: googleIcon,
  [IdentityProvider.Apple]: appleIcon,
  [IdentityProvider.Microsoft]: microsoftIcon,
};

export const idpNameTranslationMap = {
  [IdentityProvider.Google]: 'identityProviders.google',
  [IdentityProvider.Apple]: 'identityProviders.apple',
  [IdentityProvider.Microsoft]: 'identityProviders.microsoft',
};
