import React, { ReactElement, useEffect, useCallback, useState } from 'react';
import { translate, Translation } from '@teamleader/i18n';
import { Link } from '@teamleader/ahoy';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { captureException } from '@sentry/react';

import Credentials from './Credentials';
import Mfa from './MFA';
import API from '../../Infrastructure/API';
import FetchError from '../../Infrastructure/API/FetchError';
import Errors from '../../Infrastructure/API/Errors';
import {
  LoginInvalidCredentialsErrorResponse,
  LoginTooManyAttemptsErrorResponse,
} from '../../Infrastructure/API/Users/login';
import track from '../../Infrastructure/Analytics/track';
import redirectToApplication from '../../Infrastructure/Routing/redirectToApplication';
import useQuery from '../../Infrastructure/Routing/useQuery';

import styles from './styles.css';
import UrlGenerator from '../../Infrastructure/Routing/UrlGenerator';
import { IdentityProvider } from './IdentityProvider/types';
import { idpNameTranslationMap } from './IdentityProvider/constants';

const trackLoginError = (error: string | undefined) => {
  if (error === Errors.MfaIsRequired) {
    return;
  }

  track('loginPage.loginFailed', 'loginPage', { error });
};

interface FormValues {
  email?: string;
  password?: string;
  mfaCode?: string;
  trusted?: boolean;
}

const Login = () => {
  const [isMfaFormVisible, setIsMfaFormVisible] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [identityProviderError, setIdentityProviderError] = useState<string | null | ReactElement>(null);
  const query = useQuery();
  const isSessionRenewal = query.get('session_renewal') === 'true';

  const isTrialLinkHidden = query.get('hide_trial_link') === 'true';

  const appUrl = UrlGenerator().appRoot();

  const handleLogin = async (values: FormValues) => {
    track('loginPage.loginClicked', 'loginPage');

    setIdentityProviderError(null);

    try {
      await API.users.login({
        email: values.email!,
        password: values.password!,
        token: isMfaFormVisible ? values.mfaCode! : undefined,
        trusted: isMfaFormVisible && values.trusted === true ? true : undefined,
      });
    } catch (error) {
      if (error instanceof FetchError) {
        const title = error.getTitle();
        trackLoginError(title);

        if (title === Errors.InvalidCredentials) {
          const body = error.getBody<LoginInvalidCredentialsErrorResponse>();
          const attemptsLeft = body.meta.attempts.left;

          return { [FORM_ERROR]: translate('loginError.invalidCredentials', { attemptsLeft }) };
        } else if (title === Errors.MfaIsRequired) {
          setIsMfaFormVisible(true);
          return {};
        } else if (title === Errors.MfaIsInvalid) {
          return { mfaCode: translate('loginError.invalidMfaCode') };
        } else if (title === Errors.MultipleUsersFound) {
          return { [FORM_ERROR]: translate('loginError.multipleUsersFound') };
        } else if (title === Errors.TooManyAttempts) {
          const body = error.getBody<LoginTooManyAttemptsErrorResponse>();
          return {
            [FORM_ERROR]: translate('loginError.tooManyAttempts', { minutes: body.meta.attempts.window.value }),
          };
        } else if (title === Errors.AccountIsSuspended) {
          return {
            [FORM_ERROR]: (
              <Translation
                id="loginError.accountIsSuspended"
                values={{
                  link: (chunks: string[]): JSX.Element => (
                    <Link inherit element="a" href="mailto:invoicing.focus@teamleader.eu">
                      {chunks}
                    </Link>
                  ),
                }}
              />
            ),
          };
        }
      }

      captureException(error);
      return { [FORM_ERROR]: translate('loginError.generic') };
    }

    setIsRedirecting(true);

    if (isSessionRenewal && window.opener) {
      (window.opener as Window).postMessage('TL_FOCUS_SESSION_RENEWED', appUrl);
      window.close();
      return;
    }

    redirectToApplication(appUrl);
  };

  const getTranslatedIdentityProviderErrorsFromQueryParams = useCallback((): string | null | ReactElement => {
    const error = query.get('error');

    if (!error) {
      return null;
    }

    switch (error) {
      case 'account_is_suspended':
        return (
          <Translation
            id="loginError.accountIsSuspended"
            values={{
              link: (chunks: string[]): JSX.Element => (
                <Link inherit element="a" href="mailto:invoicing.focus@teamleader.eu">
                  {chunks}
                </Link>
              ),
            }}
          />
        );
      case 'user_not_found':
      case 'unauthorized': {
        const provider = query.get('provider');
        const hasProvider =
          provider != null && Object.values(IdentityProvider).some((identityProvider) => identityProvider === provider);

        if (!hasProvider) {
          return translate('loginError.generic');
        }

        return (
          <Translation
            id="loginError.noAccountForIdentityProvider"
            values={{
              provider: translate(idpNameTranslationMap[provider as IdentityProvider]),
              span: (chunks: string[]): JSX.Element => (
                <span className={styles['identity-provider-error']}>{chunks}</span>
              ),
            }}
          />
        );
      }
      case 'not_connected': {
        const provider = query.get('provider');
        const hasProvider =
          provider != null && Object.values(IdentityProvider).some((identityProvider) => identityProvider === provider);

        if (!hasProvider) {
          return translate('loginError.generic');
        }

        return (
          <Translation
            id="loginError.notConnectedWithIdentityProvider"
            values={{
              provider: translate(idpNameTranslationMap[provider as IdentityProvider]),
              span: (chunks: string[]): JSX.Element => (
                <span className={styles['identity-provider-error']}>{chunks}</span>
              ),
            }}
          />
        );
      }
      case 'invalid_identity_provider':
      default:
        return translate('loginError.generic');
    }
  }, [query]);

  const validate = (values: FormValues) => {
    const errors: Record<string, boolean | string> = {};

    if (!values.email || values.email.trim().length === 0) {
      errors.email = true;
    }

    if (!values.password || values.password.trim().length === 0) {
      errors.password = true;
    }

    if (isMfaFormVisible) {
      if (!values.mfaCode || values.mfaCode.trim().length === 0) {
        errors.mfaCode = true;
      } else if (values.mfaCode.replace(/\D/g, '').length !== 6) {
        errors.mfaCode = translate('loginError.invalidMfaCode');
      }
    }

    return errors;
  };

  useEffect(() => {
    track('loginPage.started', 'loginPage');

    setIdentityProviderError(getTranslatedIdentityProviderErrorsFromQueryParams());
  }, [getTranslatedIdentityProviderErrorsFromQueryParams]);

  return (
    <Form
      onSubmit={handleLogin}
      validate={validate}
      render={(formRenderProps) => (
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        <form onSubmit={formRenderProps.handleSubmit}>
          {isMfaFormVisible ? (
            <Mfa
              formRenderProps={formRenderProps}
              onGoBack={() => setIsMfaFormVisible(false)}
              isRedirecting={isRedirecting}
            />
          ) : (
            <Credentials
              formRenderProps={formRenderProps}
              isRedirecting={isRedirecting}
              hasTrialLinkHidden={isSessionRenewal || isTrialLinkHidden}
              identityProviderError={identityProviderError}
            />
          )}
        </form>
      )}
    />
  );
};

export default Login;
