import { IdentityProvider } from './types';

export const getIdentityProviderRedirectUrl = (identityProvider: IdentityProvider, queryParams: URLSearchParams) => {
  const redirectUrl = `${__CONFIG__.apiUrl}/identityProviders/${identityProvider}/login`;

  const hasQueryParams = queryParams.toString().length > 0;
  if (hasQueryParams) {
    queryParams.delete('prompt');
    queryParams.delete('error');

    return `${redirectUrl}?${queryParams.toString()}`;
  }

  return redirectUrl;
};

export const getIdentityProviderCapitalCaseName = (identityProvider: IdentityProvider) => {
  return `${identityProvider.slice(0, 1).toUpperCase()}${identityProvider.slice(1)}`;
};
