import React from 'react';
import {
  Heading1,
  Button,
  Input,
  Link,
  TextSmall,
  Label,
  TextBody,
  Icon,
  Checkbox,
  IconLockSmallFilled,
  IconArrowLeftSmallOutline,
} from '@teamleader/ahoy';
import { translate, Translation } from '@teamleader/i18n';
import { Field, FormRenderProps } from 'react-final-form';
import { getFormFieldErrorFromMeta } from '../../Infrastructure/Forms/utils';

const MFA = ({
  formRenderProps,
  onGoBack,
  isRedirecting,
}: {
  formRenderProps: FormRenderProps;
  onGoBack: () => void;
  isRedirecting: boolean;
}) => {
  const { submitting, submitError } = formRenderProps;

  return (
    <div>
      <Heading1 marginBottom={3}>{translate('mfaTitle')}</Heading1>

      <TextBody color="neutral" tint="darkest" marginBottom={4}>
        <Translation
          id="mfaDescription"
          values={{
            link: (chunks: string[]): JSX.Element => (
              <Link
                inherit={false}
                element="a"
                href="https://support.focus.teamleader.eu/hc/articles/25692015506577?&utm_campaign=app+knowledgebase+views"
                rel="noreferrer noopener"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </TextBody>

      <Field name="mfaCode">
        {({ input, meta }) => {
          return (
            <Label marginBottom={3}>
              {translate('mfaCode')}
              <Input
                type="text"
                inputMode="numeric"
                pattern="[0-9]*"
                autoComplete="one-time-code"
                autoFocus
                placeholder={translate('code')}
                icon={<IconLockSmallFilled />}
                prefix={
                  <Icon color="neutral" tint="darkest">
                    <IconLockSmallFilled />
                  </Icon>
                }
                {...input}
                error={getFormFieldErrorFromMeta(meta)}
                size="large"
              />
            </Label>
          );
        }}
      </Field>

      {submitError && (
        <TextSmall marginVertical={3} color="ruby" tint="dark">
          {submitError}
        </TextSmall>
      )}

      <Button
        size="large"
        level="primary"
        fullWidth
        marginVertical={3}
        type="submit"
        processing={submitting || isRedirecting}
      >
        {translate('validateMfaCode')}
      </Button>

      <Field name="trusted" initialValue={false}>
        {({ input }) => {
          return (
            <Checkbox
              label={translate('trustDevice', { amountOfDays: 7 })}
              onChange={input.onChange}
              checked={input.value}
              marginBottom={5}
            />
          );
        }}
      </Field>

      <Link onClick={onGoBack} inherit={false} icon={<IconArrowLeftSmallOutline />} element="button">
        {translate('goBack')}
      </Link>
    </div>
  );
};

export default MFA;
