import React from 'react';
import cx from 'classnames';

import styles from './styles.css';
import useLocale from '../../Infrastructure/I18n/useLocale';

const InsightsRelease = () => {
    const locale = useLocale();
    const language = (locale || '').split('-')[0];

    return <div className={cx(styles['background'], styles[`language-${language.toLowerCase()}`])} />;
};

export default InsightsRelease;
