import { useEffect, useMemo } from 'react';
import ping from '../API/Heartbeat/ping';
import redirectToApplication from './redirectToApplication';
import UrlGenerator from './UrlGenerator';

const RedirectIfLoggedIn = () => {
  const appUrl = UrlGenerator().appRoot();

  const search = window.location.search;
  const isLoginPrompt = useMemo(() => new URLSearchParams(search).get('prompt') === 'login', [search]);

  useEffect(() => {
    if (isLoginPrompt || window.opener) {
      return;
    }

    const doPingAndRedirectIfAlreadyLoggedIn = async () => {
      try {
        await ping();
      } catch (error) {
        return;
      }
      redirectToApplication(appUrl);
    };

    void doPingAndRedirectIfAlreadyLoggedIn();
  }, [appUrl, isLoginPrompt]);

  return null;
};

export default RedirectIfLoggedIn;
