import React from 'react';
import { translate } from '@teamleader/i18n';
import { Heading1, Heading2 } from '@teamleader/ahoy';

import styles from './styles.css';

const Default = () => {
  return (
    <div className={styles['default']}>
      <div className={styles['slogan']}>
        <Heading1 className={styles['title']} marginBottom={3}>
          {translate('marketingBanners.default.slogan.title')}
        </Heading1>
        <Heading2 className={styles['subtitle']}>{translate('marketingBanners.default.slogan.subtitle')}</Heading2>
      </div>
    </div>
  );
};

export default Default;
