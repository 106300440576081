import { FieldMetaState } from 'react-final-form';

export const getFormFieldErrorFromMeta = (meta: FieldMetaState<any>): any => {
  if (!meta.touched || meta.submitting) {
    return;
  }

  if (meta.error) {
    return meta.error;
  }

  if (!meta.dirtySinceLastSubmit && meta.submitError) {
    return meta.submitError;
  }
};
