const redirectToApplication = (appUrl: string) => {
  const intended = new URL(window.location.href).searchParams.get('intended');

  if (intended) {
    window.location.href = `${appUrl}/${intended.replace(/^\//, '')}`;
    return;
  }

  window.location.href = appUrl;
};

export default redirectToApplication;
