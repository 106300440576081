import React, { useCallback, useState } from 'react';
import { Provider } from '@teamleader/i18n';

import Context from './Context';

interface ProviderProps {
  children: React.ReactNode;
}

const I18nProvider: React.FC<ProviderProps> = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useState<string | null>(null);

  const getPath = useCallback((locale: string) => {
    if (!__TRANSLATIONS_MANIFEST__) {
      setCurrentLocale('en');
      return `/translations.json`;
    }

    if (__TRANSLATIONS_MANIFEST__[locale]) {
      setCurrentLocale(locale);
      return `/translations/${__TRANSLATIONS_MANIFEST__[locale]}`;
    }

    const slugs = locale.split('-');
    if (slugs[0] !== locale && __TRANSLATIONS_MANIFEST__[slugs[0]]) {
      setCurrentLocale(slugs[0]);
      return `/translations/${__TRANSLATIONS_MANIFEST__[slugs[0]]}`;
    }

    setCurrentLocale('en');
    return `/translations/${__TRANSLATIONS_MANIFEST__['en']}`;
  }, []);

  return (
    <Provider path={getPath} namespace="domains.auth" locale={__CONFIG__.localeOverwrite || navigator.language}>
      <Context.Provider value={currentLocale}>{children}</Context.Provider>
    </Provider>
  );
};

export default I18nProvider;
